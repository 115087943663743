<template>
  <div id="media-page">
    <AppContent
      class="media-content"
      :isFluid="false"
      :isContentContainer="true"
      :withSidebar="true">
      <b-row class="mt-3" align-v="start">
        <b-col
          cols="6"
          md="1"
          lg="1"
          xl="1"
          order="1"
          order-md="1">
          <div class="left-actions text-left">
            <i
              class="far fa-arrow-alt-circle-left small-icon"
              @click="back()">
            </i>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="10"
          lg="10"
          xl="10"
          order="3"
          order-md="2">
          <div class="video-wrapper">
            <div class="video-container">
              <div class="topbar">
                <button
                  class="btn btn-default backtohome-btn"
                  type="button">
                  <i class="fas fa-home text-close"> Back to Home </i>
                </button>
              </div>
              <!-- Media -->
              <div
                :class="`wistia_embed wistia_async_${$route.params.wistiaId}
                  videoFoam=true`" 
                  style="height:100%;position:relative;width:100%">
              </div>
            </div>
            <p class="title"> {{ media.media_title }} </p>
          </div>
        </b-col>

        <b-col
          cols="6"
          md="1"
          lg="1"
          xl="1"
          order="2"
          order-md="3">
          <div class="right-actions out text-right">
            <i
              class="fas fa-heart small-icon favorite"
              @click="removeFavorite()"
              v-if="media.favorite == 1">
            </i>
            <i
              class="fas fa-heart small-icon"
              @click="addFavorite()"
              v-else>
            </i>
          </div>
        </b-col>
      </b-row>
    </AppContent>
    <!-- Report Feedback -->
    <div class="feedback-wrapper" v-if="showReportForm">
      <report-feedback @close-report-feedback="showReportFeedback" />
    </div>
    <Fab
      :position="'bottom-right'"
      :bg-color="'#eaa627'"
      :main-icon="'feedback'"
      :main-tooltip="'Report Feedback'"
      :enable-rotation="true"
      @click.native="showReportFeedback"
    />

    <!-- Modal -->
    <modals-container />

    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import LevelUpMixin from '@/mixins/LevelUpMixin';
  
  export default {
    name : 'MediaPage',
    head : {
      title : {
        inner      : 'Media',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    mixins : [
      LevelUpMixin,
    ],
    data() {
      return {
        loading        : false,
        media          : {},
        secondsWatched : 0,
        showReportForm : false,
        exceeded       : false,
        curViewCount   : 0,
      }
    },
    computed : {
      ...mapGetters({
        user    : 'user/user',
        profile : 'profile/info',
      }),
    },
    components : {
      'AppContent'     : () => import('@/components/layout/AppContent'),
      'AppLoader'      : () => import('@/components/layout/AppLoader'),
      'Fab'            : () => import('vue-fab'),
      'ReportFeedback' : () => import('@/components/shared/ReportFeedback'),
    },
    methods : {

      /**
       * Add Favorite
       */
      addFavorite() {
        this.loading = true;
        const params = { mediaId : this.$route.params.id };
        if (this.profile.user_profiles_id)
          params.profileId = this.profile.user_profiles_id;

        this.$http.post('api/myvideos', params)
          .then(() => {
            // Update Video Favorites list
            this.$store.dispatch('profile/getVideoFavorites');

            // Show Success Notif
            this.getMediaInfo();
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success!',
              text  : '<b>'+ this.media.media_title +
                '</b> has been added to Favorites',
            });
          })
          .catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : 'Oops! Something went wrong.',
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },

      /**
       * Remove Favorite
       */
      removeFavorite() {
        this.loading = true;
        const params = { mediaId : parseInt(this.$route.params.id) };
        if (this.profile.user_profiles_id)
          params.profileId = this.profile.user_profiles_id;
          
        this.$http.delete('api/myvideos', {
          data : params,
        }).then(() => {
          // Update Video Favorites list
          this.$store.dispatch('profile/getVideoFavorites');

          // Show Success Notif
          this.getMediaInfo();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : '<b>' + this.media.media_title +
              '</b> has been removed from Favorites',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        }).finally(() => {
          this.loading = false;
        });
      },

      /**
       * Go back to previous page
       */
      back() {
        if (window.history.length > 1)
          this.$router.go(-1);
        else
          this.$router.push('/browse/categories');
      },

      /**
       * Get Media Info
       */
      getMediaInfo() {
        const params = { id : this.$route.params.id };
        if (this.profile.user_profiles_id)
          params.profileId = this.profile.user_profiles_id;

        this.$http.get('api/media', {
          params : params,
        }).then(response => {
          this.media = response.data;
          this.$analytics.fbq.event('ViewContent', {
            'page'        : 'video',
            'platform'    : 'website',
            'user_id'     : this.$store.getters['user/user'].user_id,
            'video_title' : response.data.media_title,
            'video_id'    : this.$route.params.id,
          });
        });
      },

      /**
       * Toggle Report Feedback form
       */
      showReportFeedback() {
        this.showReportForm = !this.showReportForm;
      },

      /**
       * Start Stream
       */
      startStream() {
        this.$http.post('api/access/startStream')
          .then(response => {
            if (response.data.code == '0002') {
              // this.exceeded = true;
              // this.$notify({
              //   group : 'notif',
              //   type  : 'error',
              //   title : 'Unable to Play',
              //   text  : 'Number of concurrent media streaming for this ' +
              //     'account has been exceeded.',
              // });
            }
          });
      },

      /**
       * Stop Stream
       */
      stopStream(){
        this.$http.post('api/access/stopStream');
      },

      /**
       * Get Current Views
       */
      // getCurView() {
      //   this.$http.get('api/access/streamCount').then(ret => {
      //     this.curViewCount = ret.data.data;
      //   });
      // },

      gtag30sec(that){
        that.$gtag.event('video_30s_listen', {
          'media_id'    : that.media.media_id,
          'media_title' : that.media.media_title,
          'platform'    : 'website',
          'environment' : process.env.VUE_APP_ENVIRONMENT,
        })
      },
      gtagtotalsec(that){
        that.$gtag.event('video_total_listen', {
          'media_id'              : that.media.media_id,
          'media_title'           : that.media.media_title,
          'platform'              : 'website',
          'environment'           : process.env.VUE_APP_ENVIRONMENT,
          'music_listen_duration' : that.secondsWatched,
        })
      },
      gtagStartListen(that){
        that.$gtag.event('video_start_listen', {
          'media_id'    : that.media.media_id,
          'media_title' : that.media.media_title,
          'platform'    : 'website',
          'environment' : process.env.VUE_APP_ENVIRONMENT,
        })
      },
    },
    created() {
      window.addEventListener('beforeunload', () => {
        //this.$http.post('api/access/stopStream');
        if(this.secondsWatched > 0)
          this.gtagtotalsec(this);

      })
    },
    mounted() {
      this.$store.dispatch('profile/getVideoFavorites');
      
      this.getMediaInfo();
      // this.getCurView();

      const that = this;

      // create element for wistia external js
      const wistiaScript = document.createElement('script');
      wistiaScript.setAttribute(
        'src',
        'https://fast.wistia.com/assets/external/E-v1.js');
      document.head.appendChild(wistiaScript);

      window._wq = window._wq || [];
      // eslint-disable-next-line no-undef
      _wq.push({
        id      : this.$route.params.wistiaId,
        options : {
          plugin : {
            'postRoll' : {
              image   : '../../../h3_cover.jpg',
              rewatch : true,
            },
          },
        },
        onHasData : (video) => {
          video.unbind('secondchange')
            .bind('secondchange', () => {
              that.secondsWatched = video.secondsWatched();
            });
        },
        onReady : (video) => {
          // triggered once video ends
          video.bind('end', function() {
            //console.log('END VIDEO TRIGGERED.....');
            // that.$http.post('api/access/stopStream');
            if (that.secondsWatched >= 30)
              that.gtag30sec(that);
            that.gtagtotalsec(that);

            if (that.secondsWatched >= 60) {
              const event = (that.user.first_video == 0) ?
                'FIRST_VIDEO' : 'WATCHED_VIDEO';

              // Add Points to User
              that.addPoints(event);
            }

            const topbar = document.querySelector('.topbar');

            topbar.style.display = 'flex';

            // append topbar and bottombar in closing screen
            video.grid.top_inside.appendChild(topbar);
            
            document
              .querySelector(".backtohome-btn")
              .addEventListener(
                "click",
                function() {
                  that.$router.push('/dashboard');
                }
              );
          });

          video.bind('play', function() {
            that.gtagStartListen(that);
            const topbar = document.querySelector('.topbar');
            // that.startStream(video);
            topbar.style.display = 'none';

            // append topbar and bottombar in closing screen
            video.grid.top_inside.appendChild(topbar);
          });

          video.bind('pause', function(){
            //console.log('PAUSE VIDEO TRIGGERED.....')
            // if(!that.exceeded)
            //   that.stopStream();
          })
        },
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #media-page {
    overflow: hidden;
    min-height: 100vh;
    background-attachment: fixed;
    background-image: $section2-bg;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .topbar {
    position: absolute;
    top: 0;
    display: none;
    width: 100%;
    justify-content: space-between;

    .text-close {
      padding: 5px;
      color: #ccc;
      cursor: pointer;
      font-size: 1.5rem;
      transition: transform 1s;
    }
  }

  .video-container {
    background-color: #000;
  }

  .fa-arrow-alt-circle-left {
    margin-bottom: 1rem;
    color: #428bca;

    &:hover {
      color: #007bff;
    }
  }

  .fa-heart {
    margin-bottom: 1rem;
    color: #6c757d;
  }

  .favorite {
    color: #007bff;
  }

  .title {
    color: $blue-text-stroke;
    font-size: 1.3em !important;
    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
    -webkit-text-stroke: 0.05px $blue-text-stroke;
  }

  .feedback-wrapper {
    bottom: 7rem;
    right: 6rem;
    position: fixed;
  }

  @media only screen and (min-width: $xs-min) {
    .media-content {
      margin-top: 2rem;
      width: 100%;
    }
    .video-container {
      border: 0.3rem solid #000;
      border-radius: 5px;
    }
    .fa-arrow-alt-circle-left,
    .fa-heart {
      font-size: 1.5em;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .media-content {
      margin-top: 2rem;
    }
    .fa-arrow-alt-circle-left,
    .fa-heart {
      font-size: 2em;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .media-content {
      margin-top: 3rem;
    }
    .fa-arrow-alt-circle-left,
    .fa-heart {
      font-size: 2.25em;
    }
    .title {
      font-size: 1.5em !important;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .media-content {
      margin: 0 auto;
      margin-left: 7rem;
      width: 55em;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .media-content {
      margin: 0 auto;
      width: 70em;
    }
    .video-container {
      border: 0.4rem solid #000;
      border-radius: 8px;
    }
  }

  @media only screen and (min-width: $special) {
    #media-page {
      padding-left: 5rem;
    }
    .media-content {
      margin: 0 auto;
      width: 75em;
    }
  }

  @media only screen and (min-width: $xl2-min) {
    .media-content {
      margin: 0 auto;
      width: 85em;
    }
    .video-container {
      border: 0.6rem solid #000;
      border-radius: 10px;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .media-content {
      margin: 0 auto;
      width: 80em;
    }
    .title {
      font-size: 1.75em !important;
    }
  }
  #wistia_simple_video_77 {
    height: 70vh;
    width: auto;
  }
  .wistia_embed {
    color: red;
    max-height: 70vh;
    
  }
  
  .wistia_embed video {
      max-height: 70vh;
      color: red;
    }
    #wistia_simple_video_81, [id^="wistia_simple_video"] {
      max-height: 70vh;

    }
</style>